import React from 'react'
import { useState } from 'react';
import Validate from './validation.js';
import FieldText from './field_text.js';
import FieldEmail from './field_email.js';
import FieldTextArea from './field_textarea.js';
import FieldErrorMessage from './field_error_message';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = '6LeddbggAAAAAEwEimZty1ha7qzclVYcSNP3OD6m';

const FormEnquiry = () => 
{
    const recaptchaRef = React.createRef();
    const [formValues, setFormValues] = useState({
        formErrors: '',
        formIsValid: false,
        formControls: {
            name: {
                type: 'text',
                value: '',
                placeholder: '',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired:  true,
                },
                errorMessages: ''
            },
            email: {
                type: 'email',
                value: '',
                placeholder: '',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true,
                    isEmail: true
                },
                errorMessages: ''
            },
            phone: {
                type: 'text',
                value: '',
                placeholder: '',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
                errorMessages: ''
            },
            enquiry: {
                type: 'text',
                value: '',
                placeholder: '',
                valid: false,
                touched: false,
                validationRules: {
                    isRequired: true
                },
                errorMessages: ''
            },
        }
    });

    const changeHandler = e => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedControls = {
            ...formValues.formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
        updatedFormElement.value = value;
        updatedFormElement.touched = true;

        let validateFormElement = [];
        validateFormElement = Validate(value, updatedFormElement.validationRules);

        updatedFormElement.valid = validateFormElement.isValid;
        updatedFormElement.errorMessages = validateFormElement.errorMessages;

        updatedControls[name] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        setFormValues({
            ...formValues,
            formControls: updatedControls,
            formIsValid: formIsValid,
        });
    }

    const submitHandler = async e => 
    {
        e.preventDefault();
        e.persist();
        
        const token = await recaptchaRef.current.executeAsync();
        const form = e.target;
        const formData = {};

        for (let formElementId in formValues.formControls) {
            formData[formElementId] = formValues.formControls[formElementId].value
        }
        
        const encode = (formData) => {
            return Object.keys(formData)
                .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
                .join("&");
        }

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ 
                "form-name": form.getAttribute("name"),
                "g-recaptcha-response": token,
                ...formData
            })
        })
        .then(() => window.location.href = '/contact/thanks/')
        .catch(error => alert(error));
    }

    return (
        <form className="font-sans text-base" name="contact" method="post" action="/contact/thanks/" data-netlify="true" data-netlify-recaptcha="true" onSubmit={submitHandler}>
            <label htmlFor="name" className="font-sans prose md:prose-lg pb-1 block">Name:<span className="text-red">*</span></label>
            <FieldText
                id="name"
                name="name"
                type={formValues.formControls.name.type}
                placeholder={formValues.formControls.name.placeholder}
                value={formValues.formControls.name.value}
                onChange={changeHandler}
                className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'prose md:prose-lg w-1/2 px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-1/2 px-2 py-1 border-2 border-grey-c mb-4 focus:outline-none'}
            />
            <FieldErrorMessage
                className={formValues.formControls.name.touched && !formValues.formControls.name.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.name.errorMessages}
            />
            <label htmlFor="email" className="font-sans prose md:prose-lg pb-1 block">Email:<span className="text-red">*</span></label>
            <FieldEmail
                id="email"
                name="email"
                type={formValues.formControls.email.type}
                placeholder={formValues.formControls.email.placeholder}
                value={formValues.formControls.email.value}
                onChange={changeHandler}
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'prose md:prose-lg w-full px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border-2 border-grey-c mb-4 focus:outline-none'}
            />
            <FieldErrorMessage
                className={formValues.formControls.email.touched && !formValues.formControls.email.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.email.errorMessages}
            />
            <label htmlFor="phone" className="font-sans prose md:prose-lg pb-1 block">Phone:<span className="text-red">*</span></label>
            <FieldText
                id="phone"
                name="phone"
                type={formValues.formControls.phone.type}
                placeholder={formValues.formControls.phone.placeholder}
                value={formValues.formControls.phone.value}
                onChange={changeHandler}
                className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'prose md:prose-lg w-1/2 px-2 py-1 border-2 border-red mb-1 focus:outline-none' : 'prose md:prose-lg w-1/2 px-2 py-1 border-2 border-grey-c mb-4 focus:outline-none'}
            />
            <FieldErrorMessage
                className={formValues.formControls.phone.touched && !formValues.formControls.phone.valid ? 'prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.phone.errorMessages}
            />
            <label htmlFor="enquiry" className="font-sans prose md:prose-lg pb-1 block">Enquiry:<span className="text-red">*</span></label>
            <FieldTextArea
                id="enquiry"
                name="enquiry"
                type={formValues.formControls.enquiry.type}
                placeholder={formValues.formControls.enquiry.placeholder}
                value={formValues.formControls.enquiry.value}
                onChange={changeHandler}
                className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'prose md:prose-lg w-full px-2 py-1 border-2 border-red h-40 focus:outline-none' : 'prose md:prose-lg w-full px-2 py-1 border-2 border-grey-c mb-4 h-40 focus:outline-none'}
            />
            <FieldErrorMessage
                className={formValues.formControls.enquiry.touched && !formValues.formControls.enquiry.valid ? 'block prose md:prose-lg text-red mb-4' : 'hidden'}
                errorMessages={formValues.formControls.enquiry.errorMessages}
            />
            <FieldErrorMessage
                className={formValues.formErrors ? 'block field__errors' : 'hidden field__errors'}
                errorMessages={formValues.formErrors}
            />
            <ReCAPTCHA
                size="invisible"
                ref={recaptchaRef}
                sitekey={SITE_KEY}
            />
            <button type="submit"
                className="rounded-lg font-sans font-bold text-lg text-white py-3 px-6 inline-block bg-blue focus:outline-none disabled:bg-grey-e disabled:text-blue disabled:cursor-not-allowed"
                disabled={!formValues.formIsValid}
            >
                Submit
            </button>
        </form>
    )
}

export default FormEnquiry